
import * as imagekitRuntime$3PJ3osRcox from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/imagekit'
import * as ipxRuntime$zCFHrDDWGT from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as netlifyRuntime$2xGLoGD2LP from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "netlify",
  "domains": [
    "img.youtube.com",
    "i.vimeocdn.com"
  ],
  "alias": {
    "/youtube": "https://img.youtube.com",
    "/vimeo": "https://i.vimeocdn.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imagekit']: { provider: imagekitRuntime$3PJ3osRcox, defaults: {"baseURL":"https://ik.imagekit.io/mvcommercial/","domains":["mvcommercial.s3.eu-west-2.amazonaws.com"]} },
  ['ipx']: { provider: ipxRuntime$zCFHrDDWGT, defaults: {} },
  ['netlify']: { provider: netlifyRuntime$2xGLoGD2LP, defaults: {} }
}
        