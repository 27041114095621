<template>
  <div class="bg-mv-black fixed text-white w-full">
    <!-- Main Menu -->
    <Popover :class="!showFullLogo ? mode == 'sales' ? 'border-b-mv-red border-b-4' : 'border-b-mv-blue border-b-4' : ''">
      <div class="mx-auto max-w-7xl">
        <!-- search box -->
        <div class="" style="height: 133.656px" v-if="showSearch">
          <search @closeSearch="showSearch = false" />
        </div>

        <div
          class="flex items-center py-5 md:py-6 px-5 md:justify-start justify-end relative"
          v-else
        >
          <!--Logo -->


          <div
            class="absolute left-4"
            :class="!showFullLogo ? 'top-0 h-full' : ''"
            to="/"
          >
            <div class="flex-col items-baseline h-full">
              <div
                class="bg-white"
                :class="showFullLogo ? 'h-20' : 'h-10'"
              ></div>
              <NuxtLink href="/">
                 <img
                  :class="showFullLogo ? 'w-24 lg:w-36' : 'w-24 lg:w-28'"
                  class="mt-auto transition-all duration-300 
                ease-in-out "
                  :src="mode == 'sales' ? '/assets/img/brand/logo.webp' : '/assets/img/brand/r-logo.webp'"
                  alt="MV Commercial logo"
                />
              </NuxtLink>
            </div>
          </div>

          <div class="-mr-2 -my-2 md:hidden flex">
            <NuxtLink to="/quote/quick-quote"
              ><div
                class="text-sm hidden md:flex text-white font-bold my-auto mr-8 mt-1 bg-mv-green rounded-md px-4 py-3 hover:bg-green-500 cursor-pointer"
              >
                GET A QUICK QUOTE 

              </div></NuxtLink
            >

            <PopoverButton
              class="bg-mv-black rounded-md p-2 align-middle flex items-center justify-center text-white hover:text-gray-400 focus:outline-none border-white border" id="mobile-menu" aria-haspopup="true"
            >
              <span class="sr-only">Open menu</span>
              <span class="font-bold mr-3">Menu</span>
              <i class="fa far fa-bars fa-xl" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden md:flex ml-44 space-x-2">
            <!-- <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-white' : 'text-white',
                  'group rounded-md inline-flex items-center text-xxs font-semibold hover:text-gray-200 focus:outline-none',
                ]"
              >
                <span>Crane Trucks</span>
                <i
                  class="fas fa-chevron-down mt-5 fa-xs"
                  :class="[
                    open ? 'text-gray-700' : 'text-gray-600',
                    'ml-2 h-5 w-5 group-hover:text-gray-800',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  v-slot="{ close }"
                >
                  <div
                    class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                  >
                    <div
                      class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
                    >
                      <NuxtLink
                        v-for="item in getHelp"
                        :key="item.name"
                        :to="item.href"
                        class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                        @click="close()"
                      >
                        <i
                          :class="item.icon"
                          class="flex-shrink-0 h-6 w-6 text-black"
                          aria-hidden="true"
                        />

                        <div class="ml-4">
                          <p class="text-base font-semibold text-gray-900">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-gray-800">
                            {{ item.description }}
                          </p>
                        </div>
                      </NuxtLink>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover> -->

            <!-- <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-white' : 'text-white',
                  'group rounded-md inline-flex items-center  font-semibold hover:text-white focus:outline-none',
                ]"
              >
                <span class="uppercase text-xxs">Crane Trucks</span>
                <i
                  class="fas fa-chevron-down mt-5 fa-xs"
                  :class="[
                    open ? 'text-white' : 'text-white',
                    'ml-2 h-5 w-5 group-hover:text-white',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  v-slot="{ close }"
                >
                  <div
                    class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                  >
                    <div class="relative grid bg-white px-5 py-6">
                      <div v-for="item in craneTrucks" :key="item.name" @click="close()">
                        <div
                          class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100 my-1 border-b cursor-pointer"
                          @click="goToCraneTrucksSearch(item.params)"
                          v-if="item.count > 0 || item.name == 'any'"
                        >
                          <span class="text-gray-500"></span>

                           <img :src="item.icon" class="w-20" />

                          <div class="ml-4">
                            <p class="text-base font-semibold text-gray-900">
                            </p>
                            <p class=" text-sm text-gray-800 mt-2 font-bold">
                              {{ item.description }} ({{ item.count }})
                            </p>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover> -->
                        <NuxtLink
              to="/main-search"
              @click="()=>{clearFilters(); setMode('sales');}"
              class="flex items-center rounded-md text-xxs font-semibold uppercase cursor-pointer hover:bg-gray-800 px-4 py-2"
            >
              <i class="fa fa-circle mr-2 fa-xs"  :class=" mode == 'sales' ? 'text-mv-red' : 'text-mv-blue'"></i> Sales
            </NuxtLink>

                        <NuxtLink
              to="/main-search"
              @click="goToRentalSearch(close, false)"
              class="flex items-center rounded-md text-xxs font-semibold uppercase cursor-pointer hover:bg-gray-800 px-4 py-2"
            >
              <i class="fa fa-circle mr-2 fa-xs"  :class=" mode == 'sales' ? 'text-mv-red' : 'text-mv-blue'"></i> Rental
            </NuxtLink>

            <NuxtLink
              to="/truck-finance"
              class="lg:flex hidden items-center rounded-md text-xxs font-semibold uppercase hover:bg-gray-800 px-4 py-2"
            >
             <i class="fa fa-circle mr-2 fa-xs"  :class=" mode == 'sales' ? 'text-mv-red' : 'text-mv-blue'"></i> Finance
            </NuxtLink>

            <NuxtLink
              to="/our-services"
              class="flex items-center rounded-md text-xxs font-semibold uppercase hover:bg-gray-800 px-4 py-2"
            >
             <i class="fa fa-circle mr-2 fa-xs"  :class=" mode == 'sales' ? 'text-mv-red' : 'text-mv-blue'"></i> Our Services
            </NuxtLink>

         




             <!-- <a
              href="https://www.mvtruckrental.com/"
              target="_blank"
              class="flex items-center rounded-md text-xxs font-semibold uppercase"
            >
             <i class="fa fa-circle mr-2 fa-xs"  :class=" mode == 'sales' ? 'text-mv-red' : 'text-mv-blue'"></i>  Rental
            </a> -->


            <NuxtLink
              to="/about-us"
              class="flex items-center rounded-md text-xxs font-semibold uppercase hover:bg-gray-800 px-4 py-2"
            >
             <i class="fa fa-circle mr-2 fa-xs"  :class=" mode == 'sales' ? 'text-mv-red' : 'text-mv-blue'"></i>  About Us
            </NuxtLink>

            <NuxtLink
              to="/contact"
              class="flex items-center rounded-md text-xxs font-semibold uppercase hover:bg-gray-800 px-4 py-2"
            >
              <i class="fa fa-circle mr-2 fa-xs"  :class=" mode == 'sales' ? 'text-mv-red' : 'text-mv-blue'"></i> Contact
            </NuxtLink>

            <!-- <i
              @click="showSearch = true"
              class="fa fa-search mt-3 w-6 h-6 cursor-pointer text-white hover:text-gray-500"
            /> -->
          </PopoverGroup>
          <div class="hidden xl:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="tel:08008620127"
              class="whitespace-nowrap font-semibold text-white px-5 py-3 rounded-md shadow-sm text-xxs"
              ><i class="fa fa-phone mr-3"></i>0800 862 0127</a
            >
            <NuxtLink
              to="/quote/quick-quote"
              class="ml-8 whitespace-nowrap font-semibold inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-sm shadow-sm text-sm text-white bg-mv-green hover:bg-green-700"
            >
              GET A QUICK QUOTE 
</NuxtLink
            >
          </div>
        </div>
      </div>

      <!-- ******** Mobile Menu ********* -->
      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50 text-gray-800"
          v-slot="{ close }"
        >
          <div
            class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white"
          >
            <div class="pt-5 pb-6 px-5">
              <div class="flex items-center justify-between">
                <NuxtLink @click="close()" to="/">
                  <div>
                     <NuxtLink href="/">
                 <img
                  class="mt-auto transition-all duration-300 
                ease-in-out w-32"
                  :src="mode == 'sales' ? '/assets/img/brand/logo.webp' : '/assets/img/brand/r-logo.webp'"
                  alt="MV Commercial logo"
                />
              </NuxtLink>
                  </div>
                </NuxtLink>

                <div class="-mr-2">
                  <PopoverButton
                    class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-600 hover:text-gray-800 hover:bg-gray-100 focus:outline-none"
                  >
                    <span class="sr-only">Close menu</span>
                    <i
                      class="h-6 w-6 fa far fa-times fa-xl mt-6 text-gray-700"
                      aria-hidden="true"
                    />
                  </PopoverButton>
                </div>
              </div>
              <div class="mt-6">
                <!-- <div class="flex w-full items-center" style="height: 133.656px">
                <search @closeSearch="showSearch = false" />
              </div> -->

                <!-- Crane Trucks -->
          <!-- <transition-group name="switch" mode="out-in">
                <div
                  v-if="!mobileMenuCategory"
                  class="py-4 justify-between w-full flex items-center rounded-md hover:bg-gray-50 text-base font-semibold text-gray-900 cursor-pointer border-b"
                  @click="mobileMenuCategory = 'crane-trucks'"
                >
                  <div class="flex ml-3">
                    Crane Trucks
                  </div>
                  <div>
                   <i class="h-6 w-6 fa far fa-chevron-right fa-sm mt-4" aria-hidden="true" />
                  </div>
                </div>

                <nav v-if="mobileMenuCategory == 'crane-trucks'" class="grid gap-y-8">
                  <div
                    class="-m-3 px-3 py-4 flex items-center rounded-md hover:bg-gray-50 cursor-pointer border-b"
                    @click="mobileMenuCategory = false"
                  >
                   <i class="h-6 w-6 fa far fa-chevron-left fa-sm mt-6" aria-hidden="true" />
                    Go back
                  </div>
                  
                   <div v-for="item in craneTrucks" :key="item.name" @click="close()">
                        <div
                          class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100 my-1 border-b cursor-pointer"
                          @click="goToCraneTrucksSearch(item.params)"
                          v-if="item.count > 0 || item.name == 'any'"
                        >
                          <span class="text-gray-500"></span>

                           <img :src="item.icon" class="w-20" />

                          <div class="ml-4">
                            <p class="text-base font-semibold text-gray-900">
                            </p>
                            <p class=" text-sm text-gray-800 mt-2 font-bold">
                              {{ item.description }} ({{ item.count }})
                            </p>
                          </div>
                        </div>
                      </div>
                </nav>
              </transition-group> -->



                <!-- our services -->

            
                <!-- Sales -->
                <transition-group name="switch" mode="out-in">
                  <NuxtLink
                  @click="()=>{clearFilters(); setMode('sales'); close();}"                    
                  to="/main-search"
                    v-if="!mobileMenuCategory"
                    class="ml-3 py-4 w-full flex items-center rounded-md hover:bg-gray-50 text-base font-semibold text-gray-900 cursor-pointer border-b"
                  >
                  Sales
                  </NuxtLink>
                </transition-group>

                <!-- Rental -->

                


                <transition-group name="switch" mode="out-in">
                  <div
                    @click="goToRentalSearch(false)"
                    v-if="!mobileMenuCategory"
                    class="ml-3 py-4 w-full flex items-center rounded-md hover:bg-gray-50 text-base font-semibold text-gray-900 cursor-pointer border-b"
                  >
                   
                  Rental
                  </div>
                </transition-group>

                    <transition-group name="switch" mode="out-in">
                  <NuxtLink
                    @click="close()"
                    to="/our-services"
                    v-if="!mobileMenuCategory"
                    class="ml-3 py-4 w-full flex items-center rounded-md hover:bg-gray-50 text-base font-semibold text-gray-900 cursor-pointer border-b"
                  >
                    Our Services
                  </NuxtLink>
                </transition-group>


                <!-- About Us -->

                <transition-group name="switch" mode="out-in">
                  <NuxtLink
                    @click="close()"
                    to="/about-us"
                    v-if="!mobileMenuCategory"
                    class="ml-3 py-4 w-full flex items-center rounded-md hover:bg-gray-50 text-base font-semibold text-gray-900 cursor-pointer border-b"
                  >
                    About Us
                  </NuxtLink>
                </transition-group>

                <!-- Contact Us -->
                <transition-group name="switch" mode="out-in">
                  <NuxtLink
                    @click="close()"
                    to="/contact"
                    v-if="!mobileMenuCategory"
                    class="ml-3 py-4 w-full flex items-center rounded-md hover:bg-gray-50 text-base font-semibold text-gray-900 cursor-pointer border-b"
                  >
                    Contact Us
                  </NuxtLink>
                </transition-group>
              </div>
            </div>
            <div class="py-6 px-5 space-y-6"></div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script setup>

import { useFiltersStore } from "~/store/filters";
import { useWebsiteModeStore } from "~/store/websiteMode";


const websiteModeStore = useWebsiteModeStore();

const { mode } = storeToRefs(websiteModeStore);
const {setMode} = websiteModeStore;


import { storeToRefs } from "pinia";

const filtersStore = useFiltersStore();
const { addValueToFilterList, removeFilter, setPageNumber } = filtersStore;
const { filtersList } = storeToRefs(filtersStore);
const { clearFilters } = filtersStore;


const router = useRouter();


import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { ref } from "vue";
// import {
//   BookmarkAltIcon,
//   CalendarIcon,
//   ChartBarIcon,
//   ChevronRightIcon,
//   ChevronLeftIcon,
//   CursorClickIcon,
//   InformationCircleIcon,
//   MapIcon,
//   MenuIcon,
//   PuzzleIcon,
//   NewspaperIcon,
//   PaperAirplaneIcon,
//   QuestionMarkCircleIcon,
//   UsersIcon,
//   PhoneIcon,
//   PlayIcon,
//   RefreshIcon,
//   SearchIcon,
//   ShieldCheckIcon,
//   SpeakerphoneIcon,
//   StatusOnlineIcon,
//   ChatIcon,
//   SupportIcon,
//   UserCircleIcon,
//   ViewGridIcon,
//   XIcon,
// } from "@heroicons/vue/outline";
// import { ChevronDownIcon } from "@heroicons/vue/solid";
import { onMounted, onBeforeMount } from "@vue/runtime-core";
import axios from "axios";

const config = useRuntimeConfig();

const recentPosts = ref();
const events = ref();
const showSearch = ref();

const callsToAction = ref([]);

const getHelp = ref();
const craneTrucks = ref();
const aboutUs = ref();
const news = ref();
const showFullLogo = ref(true);

async function getCraneCount(craneManufacturer) {
  return await axios.get(
    config.public["VEHICLE_API_BASE_URL"] +
      "/website-api/get-crane-count/" +
      craneManufacturer
  );
}

const mobileMenuCategory = ref(false);

  function goToCraneTrucksSearch(params) {


    if (params == 'allCraneTrucks' ) {

    clearFilters();

    addValueToFilterList("all_crane_trucks", "All Crane Trucks");

  }else{

    clearFilters();

    addValueToFilterList("Crane Type", params);
  }

      router.push({
      name: "main-search",
    });
  }


  function goToRentalSearch(close, mobile = false) {

    if(mobile){
      close();
    }
  
    clearFilters();
    

    setMode("rental");


    

    addValueToFilterList("rental", "Lease, Hire & Rent to Buy");

    router.push({
      name: "main-search",
    });

    
  }



function handleScroll() {
  if (process.client) {
    var currentScrollPosition = window.scrollY;

    if (currentScrollPosition < this.scrollPosition) {
      showFullLogo.value = true;
    } else {
      showFullLogo.value = false;
    }

    this.scrollPosition = window.scrollY;
  }
}

let totalCraneTrucks = 0;

onMounted(() => {
  if (process.client) {
    window.addEventListener("scroll", handleScroll);
  }

  //we don't use these counts in the menu any more!

  // craneTrucks.value.forEach((craneTruck) => {

  //   getCraneCount(craneTruck.name).then((res) => {
  //     craneTruck.count = res.data;
  //   });


  // });

});

onBeforeMount(() => {
  getHelp.value = [
    // {
    //   name: "How we can help",
    //   description: "",
    //   href: "/get-help",
    //   icon: "fa-solid fa-users fa-xl font-bold mt-3",
    // },
    // {
    //   name: "Urgent Assistance Fund",
    //   description:
    //     "We can provide cash grants to help families with children who are suffering extreme hardship.",
    //   href: "/get-help/urgent-assistance-fund",
    //   icon: "fa-solid fa-life-ring fa-xl font-bold mt-3",
    // },
    // {
    //   name: "Our Services",
    //   description:
    //     "We offer a range of services to support disadvantaged children and families across Scotland.",
    //   href: "/get-help/our-services",
    //   icon: "fa-solid fa-map fa-xl font-bold mt-3",
    // },
    // {
    //   name: "Access Your Records",
    //   description:
    //     "We can help people trace their records or those of a relative if they have spent time in our care.",
    //   href: "/get-help/access-your-records",
    //   icon: "fa-solid fa-file-certificate fa-xl font-bold mt-3",
    // },
  ];

  aboutUs.value = [
    // {
    //   name: "Our Purpose",
    //   description: "Find out our vision, mission and values and what we do.",
    //   href: "/about-us/our-purpose",
    //   icon: "fa-solid fa-circle-info fa-xl font-bold mt-3",
    // },
    // {
    //   name: "Our History",
    //   description:
    //     "Learn about Aberlour’s 145+ year journey from a single orphanage to Scotland’s children’s charity.",
    //   href: "/about-us/history/our-history",
    //   icon: "fa-solid fa-calendar-days fa-xl font-bold mt-3",
    // },
    // {
    //   name: "Our People",
    //   description: "Meet Aberlour’s Trustees and Senior Management Team.",
    //   href: "/about-us/our-people",
    //   icon: "fa-solid fa-circle-user fa-xl font-bold mt-3",
    // },
    // {
    //   name: "Policy and Influencing",
    //   description: "Young people and families influence and inform everything we say and do.",
    //   href: "/about-us/policy-and-influencing",
    //   icon: "fa-solid fa-building-columns fa-xl font-bold mt-3",
    // },
    // {
    //   name: "Abjam",
    //   description: "Abjam is an annual event open to all Aberlour young people, aged 12-25.",
    //   href: "/about-us/abjam",
    //   icon: "fa-solid fa-house-tree fa-xl font-bold mt-3",
    // },
    // {
    //   name: "Contact us",
    //   description:
    //     "Have a comment or a query? See all of the ways you can get in touch with us.",
    //   href: "/about-us/contact-us",
    //   icon: "fa-solid fa-paper-plane fa-xl font-bold mt-3",
    // },
  ];



  craneTrucks.value = [
    {
      name: "any",
      description:
        "View all our crane trucks",
      params: 'allCraneTrucks',
      icon: "/assets/img/brand/crane-logos/any.png",
    },
    {
      name: "cormach",
      description:
        "Cormach Crane trucks",
      params: "Cormach",
      icon: "/assets/img/brand/crane-logos/cormach.png",
    },
    {
      name: "palfinger",
      description:
        "Palfinger Crane Trucks",
      params: "Palfinger",
      icon: "/assets/img/brand/crane-logos/palfinger.png",
    },
    {
      name: "pm",
      description:
        "PM Crane Trucks",
      params: "PM",
      icon: "/assets/img/brand/crane-logos/pm.png",
    },
    {
      name: "fassi",
      description:
        "Fassi Crane Trucks",
      params: "Fassi",
      icon: "/assets/img/brand/crane-logos/fassi.png",
    },
    {
      name: "copma",
      description:
        "COPMA Crane Trucks",
      params: "Copma",
      icon: "/assets/img/brand/crane-logos/copma.png",
    },
    {
      name: "hiab",
      description:
        "Hiab Crane Trucks",
      params: "Hiab",
      icon: "/assets/img/brand/crane-logos/hiab.png",
    },
    {
      name: "bonfiglioli",
      description:
        "Bonfiglioli Crane Trucks",
      params: "Bonfiglioli",
      icon: "/assets/img/brand/crane-logos/bonfig.png",
    },
  ];

  

  callsToAction.value = [
    {
      name: "",
      href: "#",
      icon: "",
      colour: "mv-green",
      text: "gray-700",
      hover: "green-700",
    },
  ];

});

function parseDateString(dateString) {
  //wordpress gives us a date string of yyyyddmm

  let year = dateString.substring(0, 4);
  let month = dateString.substring(4, 6);
  let date = dateString.substring(6, 8);

  let ds = year + "-" + month + "-" + date;

  return ds;
}

const social = [
  {
    name: "Twitter",
    href: "#",
    icon: defineComponent({
      render: () =>
        h("svg", { fill: "#6A2C91", viewBox: "0 0 24 24" }, [
          h("path", {
            d: "M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84",
          }),
        ]),
    }),
  },
  {
    name: "Instagram",
    href: "#",
    icon: defineComponent({
      render: () =>
        h("svg", { fill: "#6A2C91", viewBox: "0 0 24 24" }, [
          h("path", {
            "fill-rule": "evenodd",
            d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
            "clip-rule": "evenodd",
          }),
        ]),
    }),
  },
  {
    name: "Facebook",
    href: "#",
    icon: defineComponent({
      render: () =>
        h("svg", { fill: "#6A2C91", viewBox: "0 0 24 24" }, [
          h("path", {
            "fill-rule": "evenodd",
            d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
            "clip-rule": "evenodd",
          }),
        ]),
    }),
  },
  {
    name: "Linked In",
    href: "#",
    icon: defineComponent({
      render: () =>
        h("svg", { fill: "#6A2C91", viewBox: "0 0 24 24" }, [
          h("path", {
            "fill-rule": "evenodd",
            d: "M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z",
            "clip-rule": "evenodd",
          }),
        ]),
    }),
  },
  {
    name: "You Tube",
    href: "#",
    icon: defineComponent({
      render: () =>
        h("svg", { fill: "#6A2C91", viewBox: "0 0 24 24" }, [
          h("path", {
            "fill-rule": "nonzero",
            d: "M19.606 6.995c-.076-.298-.292-.523-.539-.592C18.63 6.28 16.5 6 12 6s-6.628.28-7.069.403c-.244.068-.46.293-.537.592C4.285 7.419 4 9.196 4 12s.285 4.58.394 5.006c.076.297.292.522.538.59C5.372 17.72 7.5 18 12 18s6.629-.28 7.069-.403c.244-.068.46-.293.537-.592C19.715 16.581 20 14.8 20 12s-.285-4.58-.394-5.005zm1.937-.497C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5v-7l6 3.5-6 3.5z",
          }),
        ]),
    }),
  },
];
</script>

<style scoped>
a img {
  outline: none;
}

a {
  outline: none;
}

img {
  border: 0;
}

/* switch transitions */
.switch-enter-from,
.switch-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.switch-enter-active {
  transition: all 0.5s ease;
}
.switch-leave-active {
  transition: all 0.1s ease;
  position: absolute;
  width: 100%;
}

.show-enter-active,
.show-leave-enter {
  transform: translateX(0);
  transition: all 0.3s linear;
}
.show-enter,
.show-leave-to {
  transform: translateX(100%);
}

</style>
