
import { defineStore } from 'pinia'

export const useWebsiteModeStore = defineStore('websiteModeStore', () => {

 const mode = ref('sales');


  function setMode(val: string) {
    mode.value = val;


  }
 


  return {mode, setMode};
});
