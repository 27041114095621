<template>
  <footer class="bg-mv-black" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 px-8 sm:px-6 lg:py-16 lg:px-8">
      <div class="lg:flex lg:justify-between">
        <div class="mt-8 xl:mt-0 w-full lg:w-1/2">
          <h3
            class="text-sm font-semibold text-white tracking-wider uppercase mb-2"
          >
            Receive the latest news and offers
          </h3>

          <form
            action="#"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate newsletter-signup form-inline"
            target="_blank"
            novalidate
          >
            <div id="mc_embed_signup_scroll" class="md:flex w-full">
              <div class="mc-field-group form-group md:w-2/3">
                <input
                  type="email"
                  value=""
                  name="EMAIL"
                  autocomplete="email"
                  class="required email appearance-none min-w-0 w-full border border-transparent rounded-md py-2 px-4 text-base text-white border-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-600"
                  id="mce-EMAIL"
                  placeholder="Enter your email"
                />
              </div>

              <div
                class="cursor-pointer clear md:ml-3 bg-mv-green border border-transparent rounded-sm py-2 px-4 flex items-center justify-center text-base font-bold text-white hover:bg-green-700 mt-4 md:mt-0 focus:outline-none"
              >
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button cursor-pointer"
                />
              </div>
            </div>
          </form>

          <!-- Social Media Links -->
          <div class="flex flex-col md:order-2 mt-10 mb-10 lg:mb-0 border-b pb-8">
            <h3 class="text-sm font-semibold text-white tracking-wider mb-2">
              Follow us
            </h3>
            <div class="flex flex-wrap space-x-3 md:space-x-5 mx-auto md::justify-between">
              <a
                v-for="item in navigation.social"
                :key="item.name"
                :href="item.href"
                 class="text-mv-black bg-white p-2 rounded-full hover:text-mv-red"
                 target="_blank"

              >
                <span class="sr-only">{{ item.name }}</span>
                <component :is="item.icon" class="md:h-10 md:w-10 w-5 h-5" aria-hidden="true" />
              </a>
            </div>
          </div>

            <!-- Member Logos -->
          <div class="flex flex-col md:order-2 md:pt-8 mb-10 lg:mb-0">
            <h3 class="text-sm font-semibold text-white tracking-wider mb-2">
              Proud Members of
            </h3>
            <div class="flex gap-y-3 md:gap-y-2 gap-x-2  align-middle items-center justify-between flex-wrap">
              <a
                v-for="member in members"
                :key="member.name"
                :href="member.href"
                 class="text-mv-black p-2 hover:bg-mv-red rounded-md  border-2 cursor-pointer"
                 target="_blank"

              >
                <span class="sr-only">{{ member.name }}</span>
                <NuxtImg :alt="'Logo for '+member.name " provider="netlify" :id="'logo-'+member" width="100%" loading="lazy" :src="member.logo" class="h-8 object-fit" aria-hidden="true" />
              </a>
            </div>
          </div>


        </div>

        <!-- right column -->

        <div class="lg:mr-32 lg:ml-20">
          <div class="col-span-2 flex flex-col space-y-5">
            <h4 class="text-sm font-semibold text-white tracking-wider">
              Call Us
            </h4>
            <a
              class="text-xl text-white font-thin border-b border-b-white max-w-fit"
              href="tel:08008620127"
              >0800 862 0127</a
            >
          </div>

          <div class="xl:grid mt-10 lg:mt-0">
            <div class="grid grid-cols-2 xl:col-span-2">
              <div class="md:grid md:grid-cols-2">
                <div class="">
                  <h3
                    class="text-sm font-semibold text-white tracking-wider uppercase"
                  ></h3>
                  <ul role="list" class="mt-4 space-y-2">
                    <li v-for="item in navigation.getHelp" :key="item.name">
                      <NuxtLink
                        :to="item.href"
                        class="text-sm hover:text-white"
                        :class="
                          item.name == 'Get Help'
                            ? 'text-white font-bold'
                            : 'text-gray-300'
                        "
                      >
                        {{ item.name }}
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="md:grid md:gap-8 m">
                <div class="md:mt-0">
                  <h3
                    class="text-sm font-semibold text-white tracking-wider uppercase"
                  ></h3>
                  <ul role="list" class="mt-4 space-y-2">
                    <li v-for="item in navigation.helpUs" :key="item.name">
                      <NuxtLink
                        :to="item.href"
                        class="text-sm hover:text-white"
                        :class="
                          item.name == 'Help Us'
                            ? 'text-white font-bold'
                            : 'text-gray-300'
                        "
                      >
                        {{ item.name }}
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

          <div
        class="mt-8 text-gray-300 text-xs pt-8 w-full flex lg:justify-between flex-col lg:flex-row text-center"
      >
      <h2 class="w-full justify-center">Search our trucks and trailers by body type</h2>


      </div>
          <div
        class=" text-gray-300 text-xs pt-3 w-full flex lg:justify-between flex-col lg:flex-row text-center"
      >
        <div class="text-white">
          <a v-for="(key, bodyType) in bodyTypes" class="border-r border-gray-300 px-3 " :href="'/body-type/'+key">{{ bodyType }}</a>
        </div>
        
      </div>


          <div
        class="mt-8 text-gray-300 text-xs pt-8 w-full flex lg:justify-between flex-col lg:flex-row text-center"
      >
      <h2 class="w-full justify-center">Search our trucks and trailers by Weight</h2>


      </div>
          <div
        class=" text-gray-300 text-xs pt-3 w-full flex lg:justify-between flex-col lg:flex-row text-center"
      >
        <div class="text-white mx-auto">
          <a v-for="(key, weight) in weights" class="border-r border-gray-300 px-3 " :href="'/weights/'+key">{{ weight }}</a>
        </div>
        
      </div>

      <!--bottom row -->
      <div
        class="mt-8 text-gray-300 text-xs pt-8 w-full flex lg:justify-between flex-col lg:flex-row text-center"
      >
        <div>
          <a class="border-r border-gray-300 px-3" href="/sitemap.xml">Sitemap</a>
          <a class="border-r border-gray-300 px-3" href="/terms-of-use">Terms of use</a>
          <a class="border-r border-gray-300 px-3" href="/privacy-policy">Privacy policy</a>
          <a target="_blank" class="border-r border-gray-300 px-3" href="/documents/contract-hire-terms-and-conditions.pdf">Contract Hire Terms</a>
          <a target="_blank" class="border-r border-gray-300 px-3" href="/documents/liability-certificate-2023-24.pdf">Liability Certificate</a>
          <a class="px-3" href="/modern-slavery">Modern Slavery </a>
        </div>
        <div>
          <div class="mt-8 text-xs text-gray-300 md:mt-0 text-center w-full">
            &copy; 2023 - All rights reserved by MV Commercial
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { defineComponent } from "vue";
const config = useRuntimeConfig();


const { data: bodyTypes } = await useFetch(
  config.public["VEHICLE_API_BASE_URL"] + "/website-api/get-body-types"
);

const { data: weights } = await useFetch(
  config.public["VEHICLE_API_BASE_URL"] + "/website-api/get-web-content-list/weight"
);

const navigation = {
  getHelp: [
    { name: "Fabricating", href: "/our-services/fabrication" },
    { name: "Engineering", href: "/our-services/engineering" },
    { name: "Design", href: "/our-services/design" },
    { name: "Livery Design", href: "/our-services/livery" },
    { name: "Painting", href: "/our-services/painting" },
    { name: "Lighting & Safety", href: "/our-services/lighting" },
    { name: "Finance", href: "/truck-finance" },
    { name: "Blog", href: "/blog" },
  ],
  helpUs: [
    // { name: "FAQs", href: "#" },
    { name: "Contact Us", href: "/contact" },
    // { name: "Careers", href: "#" },
    { name: "Find Your Local Branch", href: "/contact#branch" },
    { name: "Apply for an Operator's Licence", href: "/compliance/how-to-apply-for-an-operators-licence" },
    // { name: "MV Truck Racing", href: "#" },
  ],
  about: [],
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/MVCommercial",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              d: "M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84",
            }),
          ]),
      }),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/mvcommercial/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/mvcommercial",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "Linked In",
      href: "https://www.linkedin.com/company/mv-commercial-ltd/",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "evenodd",
              d: "M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z",
              "clip-rule": "evenodd",
            }),
          ]),
      }),
    },
    {
      name: "You Tube",
      href: "https://www.youtube.com/@MVCommercialLtd",
      icon: defineComponent({
        render: () =>
          h("svg", { fill: "currentColor", viewBox: "0 0 24 24" }, [
            h("path", {
              "fill-rule": "nonzero",
              d: "M19.606 6.995c-.076-.298-.292-.523-.539-.592C18.63 6.28 16.5 6 12 6s-6.628.28-7.069.403c-.244.068-.46.293-.537.592C4.285 7.419 4 9.196 4 12s.285 4.58.394 5.006c.076.297.292.522.538.59C5.372 17.72 7.5 18 12 18s6.629-.28 7.069-.403c.244-.068.46-.293.537-.592C19.715 16.581 20 14.8 20 12s-.285-4.58-.394-5.005zm1.937-.497C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5v-7l6 3.5-6 3.5z",
            }),
          ]),
      }),
    },
  ],
};



const members = [
  {
  name: "ALLMI",
  href: "https://www.allmi.com/",
  logo: "/assets/img/brand/member-logos/2023---ALLMI---White.webp",
  width: "16"
  },
  {
    name: "BVRLA",
    href: "https://www.bvrla.co.uk/",
    logo: "/assets/img/brand/member-logos/2023-Bvrla-White.webp",
      width: "16"

  },
    {
    name: "RHA",
    href: "https://www.rha.uk.net/",
    logo: "/assets/img/brand/member-logos/rha-logo.webp",
      width: "12"

  },
    {
    name: "FTA",
    href: "https://logistics.org.uk/",
    logo: "/assets/img/brand/member-logos/2023-FTA-logo.webp",
      width: "12"

  },
    {
    name: "URG",
    href: "https://unitedrentalsystem.co.uk/",
    logo: "/assets/img/brand/member-logos/urg-logo.webp",
      width: "12"

  }
]
</script>

<style>
</style>
