export default defineNuxtRouteMiddleware((to) => {

   const { $config } = useNuxtApp()

    const redirects = [
    {
      from: "/pages/hire-options", 
      to: "/"
    },
    {
      from: "/operators-licence",
      to: "/compliance/how-to-apply-for-an-operators-licence"
    },
    {
      from: "/product-groups/26-tonne/types/curtain-26tonne",
      to: "/main-search"
    },
    {
      from: "/pages/truck-finance", 
      to: "/truck-finance"
    },
    {
      from: "/crane-models/*",
      to: "/main-search"
    },
    {
      from: "/body-types/ultra-low-approach-recovery",
      to: "/body-type/ultra-low-approach-recovery"
    },
    {
      from: "/body-types/double-deck-tilt-and-slide-recovery",
      to: "/body-type/double-deck-tilt-and-slide-recovery"
    },
    {
      from: "/pages/truck-sales/used-truck-sales", 
      to: "/main-search"
    },
    {
      from: "/vehicles", 
      to: "/main-search"
    },
    {
      from: "/body-types/tipper-grab", 
      to: "/body-type/tipper-grab"
    },
    {
      from: "/body-types/tipper/*", 
      to: "/body-type/tipper"
    },
    {
      from: "/body-types/brick-grabs",
      to: "/body-type/brick-grabs"
    },
    {
      from: "/body-types/hot-box",
      to: "/body-type/hot-box"
    },
    {
      from: "/body-type/brick-grab",
      to: "/body-type/brick-grabs"
    },
    {
      from: "/body-types/tractor-unit",
      to: "/body-type/tractor-unit"
    },
    {
      from: "/body-types/trailers",
      to: "/body-type/trailers"
    },
    {
      from: "/body-types/van",
      to: "/body-type/van"
    },
    {
      from: "/body-types/hook-loader",
      to: "/body-type/hook-loader"
    },
    {
      from: "/body-types",
      to: "/main-search"
    },
    {
      from: "/body-type",
      to: "/main-search"
    },

    {
      from: "/pages/crane-trucks/new-crane-trucks",
      to: "/main-search"
    },
    {
      from: "/vehicles/daf-cf-450-32-tonne-tipper-grab-palfinger-epsilon-epsilon-m125l-for-sale-sn73yfs",
      to: "/main-search"
    },

    {
      from: "/pages/contact-us", 
      to: "/contact"
    },
    {
      from: "/pages/about-us", 
      to: "/about-us"
    },
    {
      from: "/jobs/", 
      to: "/"
    },
    {
      from: "/jobs", 
      to: "/"
    },
    // {
    //   from: "/feeds/tl.xml",
    //   to: "https://opus.cdbl.site/website-api/feed/feed_to_trucklocator"
    // }
  ];


   const redirect = redirects.find((r) => r.from === to.path)

  if (redirect) {

      // if(redirect.from.includes("feeds")){
      //  window.location.href = redirect.to;

      // }

     return redirect.to;
  }

});