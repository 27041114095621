<template>
  <NuxtLayout>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />

    <link
    href="/assets/fonts/UKNumberPlate.ttf"
    as="font"
    type="font/UKNumberPlate"
    crossorigin
    defer="true"
  />

    <NuxtPage />
  </NuxtLayout>
</template>


<script setup>
useHead({
  script: [
    {
      src: "https://kit.fontawesome.com/96b5bede38.js",
      crossorigin: "anonymous",
      defer: true,
    },
    {
      src: "//js-na1.hs-scripts.com/2327386.js",
      async: true,
      defer: true,
      crossorigin: "anonymous",
      id: "hs-script-loader",
    }

  ],
   htmlAttrs: {
      lang: 'en'
    },
    link: [
      {
        rel: 'icon',
        type: 'image/png',
        href: '/public/favicon.png'
      }
    ]
});

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk}` : '';
  }
})

// useSeoMeta({
//   title: 'MV Commercial | New and Used Trucks for Sale in the UK',
//   ogTitle: 'MV Commercial | New and Used Trucks for Sale in the UK',
//   description: 'MV Commercial is a leading supplier of new and used trucks for sale in the UK. We also specialise in truck rental, crane truck rental and tipper and grab hire.',
//   ogTitle: 'MV Commercial | New and Used Trucks for Sale in the UK',
//   ogDescription: 'MV Commercial is the UK’s largest supplier of new and used commercial vehicles. With a huge range of new and used vehicles on display including Crane Trucks, Tippers and Grabs, you’ll find the right vehicle for your business at MV Commercial.',
//   ogImage: '/assets/img/brand/logo.webp',
//   ogUrl: 'https://www.mvcommercial.com/',
//   twitterTitle: 'MV Commercial | New & Used Commercial Vehicles',
//   twitterDescription: 'MV Commercial is the UK’s largest supplier of new and used commercial vehicles. With a huge range of new and used vehicles on display, you’ll find the right vehicle for your business at MV Commercial.',
//   twitterImage: '/assets/img/brand/logo.webp',
//   twitterCard: 'summary_large_image',
// })

</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
