import { defineStore } from 'pinia'

import { useWebsiteModeStore } from "~/store/websiteMode";
import { storeToRefs } from "pinia";



export const useFiltersStore = defineStore('filterStore', () => {


  
  const websiteModeStore = useWebsiteModeStore();
  const { mode } = storeToRefs(websiteModeStore);
  const {setMode} = websiteModeStore;


  const filtersList = ref({
    Condition: ['New & Used'],
  })

  const vehicleCount = ref(0);

  function addValueToFilterList(title: string, value: string) {

    if (!filtersList.value[title]) {
      filtersList.value[title] = []
    }


    if(title == "Condition"){

      if(value == ""){
        filtersList.value[title] = [];
        filtersList.value[title].push("New & Used");
        return;
      }

      filtersList.value[title] = [];
      filtersList.value[title].push(value);
      return;
    }

    if(title == "Keyword"){

      filtersList.value[title] = [];
      filtersList.value[title].push(value);

      return;
    }


    const index = filtersList.value[title].indexOf(value);
    if (index > -1) {
      return false;
    }

    filtersList.value[title].push(value)

  }




  function removeFilter(title: string, value: string) {

    if(title == "rental"){

      setMode("sales");

    }
    
    const index = filtersList.value[title].indexOf(value);
    if (index > -1) {
      filtersList.value[title].splice(index, 1);
    }

    
    if (filtersList.value[title].length < 1) {
      delete filtersList.value[title];
    }


    //switch ALL back on if no condition is set
    if(!filtersList.value['Condition']){

      filtersList.value['Condition'] = [];
      filtersList.value['Condition'].push("New & Used");

    }

  }

  function clearFilters() {
    filtersList.value = {Condition: ['New & Used']};

  }

  function setPageNumber(page: number) {
    filtersList.value.pageNumber = page;
  }


  function getFilterList(attribute: string) {
    let array = [];

    if (!filtersList.value[attribute]) {
      return array;
    }

    return filtersList.value[attribute];
  }

 
  function hasFilters() {
    return Object.keys(filtersList.value).length > 1;
  }

  function addVehicleCount(count: number){
    vehicleCount.value = count;
  }

  function getVehicleCount(){
    return vehicleCount.value;
  }


  return { addValueToFilterList, removeFilter, clearFilters, setPageNumber, getFilterList, hasFilters, filtersList, vehicleCount, addVehicleCount, getVehicleCount }
}
);