<template>
  <TheHeader/>

    <div class="relative  lg:mx-auto ">
    <NuxtLink to="/"><img alt="truck with error sign" src="/assets/img/brand/404-background.webp" class="lg:h-screen w-full object-cover pt-20 lg:pt-0"/></NuxtLink>

    <div class="lg:absolute w-full lg:w-[400px] top-1/2 md:left-[300px] lg:left-[20em] transform lg:-translate-x-1/2 lg:-translate-y-1/2 text-center bg-white lg:border-2 border-mv-red p-4 rounded-md border-aberlour-purple shadow-lg">
    Sorry about this, Something went wrong!
       <h1 class="text-4xl font-bold mt-5">{{ error.statusCode }} Error</h1>
    <p class="text-red-500 mt-5">'{{ error.message }}'</p>
    <p class="font-bold mt-5" v-if="error.statusCode === 500">Internal Server Error</p>
    <p class="font-bold mt-5" v-if="error.statusCode === 404">Page Not Found!</p>
    <div class="flex mt-10">

    <NuxtLink to="/" class="text-white bg-mv-green p-2 rounded-sm  mx-auto">Go back to Home</NuxtLink>
    </div>
    </div>

     

</div>
<TheFooter/>
  
</template>

<script>

export default {
  props: ['error'],
  mounted() {
    // this.$store.commit('setHeaderColor', 'white');

    // window.location.reload(true);

  
  }
  
}

</script>

<style>

</style>